<template>
  <div class="Device" ref="vlbox">
    <div class="index_main" ref="index_main">
    <div class="index_right">
        <p class="search">
           <el-select v-model="searchForm.productType"  v-if="permissoin.includes('query')" placeholder="设备类型" clearable size="mini">
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select v-model="searchForm.status"  v-if="permissoin.includes('query')" placeholder="入库类型" clearable size="mini">
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-date-picker
              v-if="permissoin.includes('query')"
              size="mini"
      v-model="searchForm.date"
      type="daterange"
      value-format="yyyy-MM-dd HH:mm:ss"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
        <el-input
          type="text" v-if="permissoin.includes('query')"
          size="mini" clearable
          v-model="searchForm.deviceNum"
          placeholder=" 请输入设备号"
        ></el-input>
        <button class="btn1" v-if="permissoin.includes('query')" @click="currentPage2=1;getData()">
          <i class="el-icon-search"></i>
          查询
        </button>
         <!-- <button class="btn2" @click="modal=1">
          <i class="el-icon-plus"></i>
          新增
        </button> -->
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="productName" label="设备名称" :show-overflow-tooltip="true"
              width="180">
              <!-- <template slot-scope="scope">
                <span>{{scope.row.productName}}（{{scope.row.merchantName}}）</span>
              </template> -->
            </el-table-column>
            <el-table-column prop="merchantName" label="供应商" :show-overflow-tooltip="true"
              width="120">
            </el-table-column>
            <el-table-column prop="sn" label="设备类型">
              <template slot-scope="scope">
                <span v-if="scope.row.productType == 1">硬件材料</span>
                <span v-if="scope.row.productType == 2">软件系统</span>
                <span v-if="scope.row.productType == 3">其他服务</span>
                <span v-if="scope.row.productType == 4">商务名目</span>
                <span v-if="scope.row.productType == 5">组合包</span>
              </template>
            </el-table-column>
            <el-table-column prop="sn" label="入库类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">采购入库</span>
                <span v-if="scope.row.type == 2">退件出库</span>
                <span v-if="scope.row.type == 3">残件入库</span>
                <span v-if="scope.row.type == 4">返修入库</span>
                <span v-if="scope.row.type == 5">归还入库</span>
                <span v-if="scope.row.type == 0">其他</span>
              </template>
            </el-table-column>
            <el-table-column prop="deviceNum" label="设备号" :show-overflow-tooltip="true"
              width="200">
            </el-table-column>
            <el-table-column prop="nickName" label="入库人">
            </el-table-column>
            <el-table-column prop="orgName" label="入库时间">
              <template slot-scope="scope">
                <span class="spa_green"> {{timestampToTime1(scope.row.inWarehouse)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inWarehouseBatch" label="入库批次">
            </el-table-column>
            <el-table-column prop="orgName" label="设备状态">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0" class="colorgreen">正常</span>
                <span v-if="scope.row.status == 1" class="colorred">故障</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgName" label="发票类型/税率">
              <template slot-scope="scope">
                <span v-if="scope.row.invoiceType== 1">无票</span>
                <span v-if="scope.row.invoiceType == 2">普通发票</span>
                <span v-if="scope.row.invoiceType == 3">增值税发票</span>
                <span v-if="scope.row.invoiceType != 1">
                    <!-- <span v-if="scope.row.taxRate == 3">6%</span>
                    <span v-if="scope.row.taxRate == 4">9%</span>
                    <span v-if="scope.row.taxRate == 5">13%</span>
                <span v-if="scope.row.taxRate == 1">1%</span>
                <span v-if="scope.row.taxRate == 2">3%</span> -->

                <span v-if="scope.row.taxRate">（{{scope.row.taxRate*100+'%'}}）</span>
                </span>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          prev-text="上一页"
          next-text="下一页"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    </div>

  </div>
</template>

<script>
import {
  deviceInGetList
} from "@/api/apis.js";
import {
  timestampToTime1
} from "@/utils/getTime.js";
export default {
  data() {
    return {
      options: [],
      form: {state:0},
      info: 1,
      timestampToTime1,
      currentPage2:1,
      treeData:[],
      hide:1,
      modal:0,
      treeList:[],
      total:0,
      searchForm:{},
      permissoin:[],
      projectP:-1,
      dialogVisible:false,
      netArrUpdate:[{id:0}],
      ruleForm:{
        pwd:123456
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      timeOut:0,
      treedata:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      option1: [{
          value: '1',
          label: '硬件材料'
        }, {
          value: '2',
          label: '软件系统'
        },{
          value: '3',
          label: '其他服务'
        },{
          value: '4',
          label: '商务名目'
        },{
          value: 5,
          label: '组合包'
        }],
        // 1 采购入库 2 退件入库 3 残件入库 4 返修入库 5 归还入库 0 其他
        option2: [{
          value: '1',
          label: '采购入库'
        }, {
          value: '2',
          label: '退件出库'
        }, {
          value: '3',
          label: '残件入库'
        }, {
          value: '4',
          label: '返修入库'
        }, {
          value: '5',
          label: '归还入库'
        }, {
          value: '0',
          label: '其他'
        }],
      tableData: [],
        rules: {
          orgId: [
            { required: true, message: '选择机构', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={}
      if(this.searchForm.date){
          data={
        productType: this.searchForm.productType,
        type: this.searchForm.status,
        beginTime:this.searchForm.date[0],
        inWarehouse:this.searchForm.date[1],
        deviceNum: this.searchForm.deviceNum,
        pageNum: this.currentPage2,
        pageSize: 10,
      }
      }else{
          data={
        productType: this.searchForm.productType,
        type: this.searchForm.status,
        deviceNum: this.searchForm.deviceNum,
        pageNum: this.currentPage2,
        pageSize: 10,
      }
      }
       
      deviceInGetList(data).then(res=>{
        if(res.code==200){
          this.tableData=res.data.list
          this.total=res.data.total-0
        }else{
          this.tableData=[]
          this.total=0
        }
      })
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
  },
  mounted() {
    this.getData()
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)    
      }
    })
  },
  
};
</script>

<style lang="less" >
.Device{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  .index_right{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 208px;
        margin-right: 20px;
      }
      .el-date-editor{
        margin-right: 20px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px  #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center{
        flex: 1;
        padding-left: 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini, .el-button--mini.is-round{
          padding:  6px 12px;
          margin-left: 4px;
        }

            .line{
              border-top: 1px dashed rgb(221, 214, 214);
              width: 348px;
            }
            p{
              margin-bottom: 20px;
            }
            .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input{
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }       
      }
      .bottom{
        height: 60px;
        display: flex;
        justify-content: center;
        &>button:nth-child(1){
          margin-right: 20px;
        }
      }
    }
   }
}
</style>